
/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

form {
  width: 100%;
}

label {
  &.required:after {
    content: "*";
    color: $danger;
    margin-left: 5px;
  }
}