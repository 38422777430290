.weekday-picker,
.month-day-picker {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .btn {
    flex: 0 0 14.3%;
    margin-top: -1px;
    border-radius: 0;
  }
}